import { render, staticRenderFns } from "./Start.vue?vue&type=template&id=dc24e78c&scoped=true&"
import script from "./Start.vue?vue&type=script&lang=js&"
export * from "./Start.vue?vue&type=script&lang=js&"
import style0 from "./Start.vue?vue&type=style&index=0&id=dc24e78c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc24e78c",
  null
  
)

export default component.exports